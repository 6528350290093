import React from 'react'
import Resume from './DanielleYe_Resume.pdf'

const ContactMe = () => {
    return (
        <div className="portfolio__contact-me">
            <h1>Contact Me</h1>
            <ul>
                <li>
                    <a href={Resume} target="_blank" rel="noopener noreferrer">
                        Résumé
                    </a>
                </li>
                <li>
                    <a href="mailto:daniella.ye9@gmail.com">Email</a>
                </li>
                <li>
                    <a
                        href="https://www.linkedin.com/in/dandan-ye-b6563518b/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        LinkedIn
                    </a>
                </li>
                <li>
                    <a
                        href="https://github.com/danielleye"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Github
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default ContactMe
