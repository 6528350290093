import React from 'react'
import StickyHeader from './components/header.js'
import './App.scss'
import { Router } from '@reach/router'
import Home from './pages/Home.js'
import AboutMe from './pages/AboutMe.js'
import Portfolio from './pages/Portfolio.js'
import ContactMe from './pages/Contact.js'

const App = () => {
    return (
        <div className="App">
            <StickyHeader />
            <Router>
                <Home path="/" />
            </Router>
            <Router>
                <AboutMe path="/about-me" />
            </Router>
            <Router>
                <Portfolio path="/portfolio" />
            </Router>
            <Router>
                <ContactMe path="/contact-me" />
            </Router>
        </div>
    )
}

export default App
