import React from 'react'

const AboutMe = () => {
    return (
        <div className="portfolio__about-me">
            <h1>About Me</h1>
            <p>
                I'm a Front-end Developer based in New York. <br />
                Highly skilled with JavaScript, Node, ES5-Next, ReactJs,
                Webpack, HTML5, CSS3/SCSS, testing, GIT and various professional
                dev tools and methodologies.
            </p>
        </div>
    )
}

export default AboutMe
