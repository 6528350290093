import React from 'react'

const Home = () => {
    return (
        <div className="App__body">
            Danielle Ye
            <p>Front-end Developer</p>
        </div>
    )
}

export default Home
