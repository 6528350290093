import React from 'react'
import './header.scss'
import { Link } from '@reach/router'

const StickyHeader = () => {
    return (
        <header className="header">
            <Link className="header__name" to="/">
                Danielle Ye
                <img src="./logo.png" alt="dy-logo" />
            </Link>
            <nav>
                <input className="menu-btn" type="checkbox" id="menu-btn" />
                <label className="menu-icon" htmlFor="menu-btn">
                    <span className="navicon"></span>
                </label>
                <ul className="header__links">
                    <li className="header__links__item">
                        <Link to="/about-me">About Me</Link>
                    </li>
                    <li className="header__links__item">
                        <Link to="/portfolio">Portfolio</Link>
                    </li>
                    <li className="header__links__item">
                        <Link to="/contact-me">Contact Me</Link>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default StickyHeader
